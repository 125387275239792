import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="December 2022" />
		<NewsHeader />
		<h2>December 2022</h2>

		<h3 className="blogdate">
			Thursday, December 1, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="4">Will I see you in Kelowna?</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				There's been some recent events in my family and its clearly time I head home to spend the holidays with my loved ones.
				I'll be in Kelowna, as well as Penticton/Keremeos, for about a month!
				I'll be catching up with old buddies, but I'd also like to meet some new peeps... Wanna hear some music?
				I'd be happy to put on a little intimate show!
			</p>
			<p>
				Here's the <a href="//sidedooraccess.com/connect/iWnmJn7RmZ3JRt5rJyNW?utm_source=show-call&utm_medium=copy&utm_campaign=share&utm_content=iWnmJn7RmZ3JRt5rJyNW">show
				call</a>—hook up with me there or <Link to="/contact/">contact me</Link> if you know some cool songwriters or musicians that I should meet.
				Thanks!
			</p>

		</div>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="3">Will I see you in LA?</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				On my way home to Kelowna this year for the holidays, I am passing through Los Angeles.
				I've only been there once—for a day—and I'm keen to make some new friends. Wanna hear some music?
				I'd be happy to put on a little intimate show!
				Maybe you've got some tips on where to stay and catch some great live music;
				maybe you're feeling adventurous and want a new songwriting collaborator?
			</p>
			<p>
				Here's the <a href="//sidedooraccess.com/connect/0F0LV7ZHI1YmEiTYVnjE?utm_source=show-call&utm_medium=copy&utm_campaign=share&utm_content=0F0LV7ZHI1YmEiTYVnjE">show
				call</a>—hook up with me there or <Link to="/contact/">contact me</Link> if you have some pointers.
				Thanks!
			</p>

		</div>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="2">I have some new merch up</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				That's right, how about some impressively sized prints of my album artwork? Perfect for displaying on the wall at home or the office.
				Got a rec room? Could be nice over the pool table or foosball...
			</p>
			<p>
				Make your way to the <a href="//jesserivestmusicmerch.bigcartel.com/">display wall</a> and choose one 😉
			</p>

		</div>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Let it Snow! A holiday warmer-upper</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				I had a lot of fun with the backing vocal harmonies as well as the electric guitar instrumental break on this one!
				I especially like that this tune is a general winter/warm/romantic song, and not necessarily a Christmas song.
			</p>
			<p>
				You can check it wherever you prefer to stream or buy digital music.
				You might do that while also reading about it at my  <Link to="/music/13/">music page</Link>.
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
					src="../../images/catalog artwork/JRIVEST13 Let it Snow! front.jpg"
					alt="Jesse Rivest - Let it Snow! - cover art"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={300}
				/>
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
